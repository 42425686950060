<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        {{ $t('communication-15-17.title') }}
      </h2>
      <h3>
        {{ $t('communication-15-17.methodology') }}
      </h3>
      <div class="collaboration-desc">
        {{ $t('communication-15-17.instruction') }}
      </div>

      <div class="d-flex flex-column">
        <div class="question-radio">
          1. {{ $t('communication-15-17.question-1') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[0].choice" label="А">
              А) {{ $t('communication-15-17.answer-1-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[0].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-1-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[0].choice" label="В">
              В) {{ $t('communication-15-17.answer-1-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[0].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-1-4') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          2. {{ $t('communication-15-17.question-2') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[1].choice" label="А">
              А) {{ $t('communication-15-17.answer-2-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[1].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-2-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[1].choice" label="В">
              В) {{ $t('communication-15-17.answer-2-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[1].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-2-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          3. {{ $t('communication-15-17.question-3') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[2].choice" label="А">
              А) {{ $t('communication-15-17.answer-3-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[2].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-3-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[2].choice" label="В">
              В) {{ $t('communication-15-17.answer-3-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[2].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-3-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          4. {{ $t('communication-15-17.question-4') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[3].choice" label="А">
              А) {{ $t('communication-15-17.answer-4-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[3].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-4-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[3].choice" label="В">
              В) {{ $t('communication-15-17.answer-4-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[3].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-4-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          5. {{ $t('communication-15-17.question-5') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[4].choice" label="А">
              А) {{ $t('communication-15-17.answer-5-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[4].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-5-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[4].choice" label="В">
              В) {{ $t('communication-15-17.answer-5-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[4].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-5-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          6. {{ $t('communication-15-17.question-6') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[5].choice" label="А">
              А) {{ $t('communication-15-17.answer-6-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[5].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-6-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[5].choice" label="В">
              В) {{ $t('communication-15-17.answer-6-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[5].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-6-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          7. {{ $t('communication-15-17.question-7') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[6].choice" label="А">
              А) {{ $t('communication-15-17.answer-7-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[6].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-7-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[6].choice" label="В">
              В) {{ $t('communication-15-17.answer-7-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[6].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-7-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          8. {{ $t('communication-15-17.question-8') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[7].choice" label="А">
              А) {{ $t('communication-15-17.answer-8-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[7].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-8-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[7].choice" label="В">
              В) {{ $t('communication-15-17.answer-8-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[7].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-8-4') }}
            </el-radio>
          </div>

        </div>

        <div class="question-radio">
          9. {{ $t('communication-15-17.question-9') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[8].choice" label="А">
              А) {{ $t('communication-15-17.answer-9-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[8].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-9-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[8].choice" label="В">
              В) {{ $t('communication-15-17.answer-9-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[8].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-9-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          10. {{ $t('communication-15-17.question-10') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[9].choice" label="А">
              А) {{ $t('communication-15-17.answer-10-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[9].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-10-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[9].choice" label="В">
              В) {{ $t('communication-15-17.answer-10-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[9].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-10-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          11. {{ $t('communication-15-17.question-11') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[10].choice" label="А">
              А) {{ $t('communication-15-17.answer-11-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[10].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-11-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[10].choice" label="В">
              В) {{ $t('communication-15-17.answer-11-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[10].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-11-4') }}
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          12. {{ $t('communication-15-17.question-12') }}
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[11].choice" label="А">
              А) {{ $t('communication-15-17.answer-12-1') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[11].choice" label="Б">
              Б) {{ $t('communication-15-17.answer-12-2') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[11].choice" label="В">
              В) {{ $t('communication-15-17.answer-12-3') }}
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[11].choice" label="Г">
              Г) {{ $t('communication-15-17.answer-12-4') }}
            </el-radio>
          </div>
        </div>
      </div>


      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendIncorrectPhraseResults(false)" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      answersIncorrectPhrase: [
        { choice: "", question: "1" },
        { choice: "", question: "2" },
        { choice: "", question: "3" },
        { choice: "", question: "4" },
        { choice: "", question: "5" },
        { choice: "", question: "6" },
        { choice: "", question: "7" },
        { choice: "", question: "8" },
        { choice: "", question: "9" },
        { choice: "", question: "10" },
        { choice: "", question: "11" },
        { choice: "", question: "12" },
      ],
      score: null,
      correctAnswers: ["Г", "Г", "Г", "Г", "Б", "В", "Г", "Г", "Г", "В", "Г", "Г"],
    }
  },
  methods: {
    validateAnswers() {
      return this.answersIncorrectPhrase.some(
          (answer) => answer.choice.trim() === ""
      );
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendIncorrectPhraseResults(timer) {

      if (!timer && this.validateAnswers()) {

        this.$bvModal.hide('exit-modal');

        return Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('answerAllQuestions'),
          type: 'error'
        })
      }

      this.score = this.correctAnswers.reduce((total, correct, index) => {
        return total + (this.answersIncorrectPhrase[index].choice === correct ? 1 : 0);
      }, 0);

      const incorrectPhraseData = {
        score: this.score,
        answers: this.answersIncorrectPhrase,
      }

      let params = JSON.parse(localStorage.getItem('quiz-params-15-17'))
      params.answer_id = {...params.answer_id, incorrectPhraseData}
      localStorage.setItem('quiz-params-15-17', JSON.stringify(params))
      const quizToken = localStorage.getItem('quiz-token')

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        localStorage.setItem('current-test', 'funny-stories')

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }

      console.log('incorrectPhraseData', incorrectPhraseData)

      setTimeout(() => {
        this.$router.push({ path: '/funny-stories' })
      }, 1500)

    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerIncorrectPhrase', savedTimer);

        if (--timer < 0) {
          this.sendIncorrectPhraseResults(true)
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
    redirectIfEnded() {
      const quizToken = localStorage.getItem('quiz-token');
      const userQuizId = localStorage.getItem('user_quiz_id');
      const quizEnded = localStorage.getItem('quiz-ended');

      if (quizEnded && quizEnded === `${userQuizId}-${quizToken}`) {
        window.location.href = `${window.QABILET_ROOT}/test-results-15-17/${userQuizId}/${quizToken}`
      }
    },
  },
  mounted() {
    this.redirectIfEnded();

    if (localStorage.getItem('timerIncorrectPhrase')) {
      this.startTimer(localStorage.getItem('timerIncorrectPhrase'))
    } else {
      this.startTimer(300)
    }

    if (localStorage.getItem('current-test')) {
      this.$router.push({name: localStorage.getItem('current-test')});
    }

    const testLang = localStorage.getItem('test_lang');
    if (testLang) this.$i18n.locale = testLang;
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}


</style>
